import styled from 'styled-components'

export const Heading = styled.h3`
margin-top: var(--spacing-4);
display: flex;
justify-content: space-between;
align-items: flex-start;
`

export const Button = styled.button`
padding: var(--spacing-1) var(--spacing-4);
border: 0;
border-radius: var(--spacing-2);
background-color: gray;
background-color: lightgray;
font-size: 1rem;
cursor: pointer;
&:hover,
&:focus,
&:active {
  background-color: var(--color-secondary);
}
`
