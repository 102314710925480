import React, { useMemo } from 'react'
import parse from 'html-react-parser'

import { useRecoilValue, useResetRecoilState } from 'recoil'
import { recoilCCG } from '../../../recoil/app'

import useTranslation from '../../../hooks/useTranslation'

import { Heading, Button } from './Content.styled'

const Content = ({ pages: { nodes = [] } = {} }) => {
  const ccg = useRecoilValue(recoilCCG)
  const resetCCG = useResetRecoilState(recoilCCG)
  const {translate} = useTranslation()
  const {title, content} = useMemo(() => {
    const page = nodes.find(({ccgCodes: { nodes = [] }}) => nodes.find(({name}) => name === ccg))
    if (page)
      return page
    return {
      title: translate('No results found'),
      content: translate('No contact details could be found for the ICB'),
    }
  }, [ccg, nodes, translate])
  const clickHandler = () => void resetCCG()
  return <>
    <Heading>
      <span>{parse(title)}</span>
      <Button onClick={clickHandler}>{translate('Change')}</Button>
    </Heading>
    {parse(content)}
  </>
}

export default Content
